jQuery( document ).ready(function($) {
    $('.menu-toggle').on('click', function(e){
        e.preventDefault();
        $('.menu-menu-container').toggleClass('active');
    });

    $('.selector').on('click', function(e){
        e.preventDefault();
        let targets = $(this).attr('data-target');
        let newActive = $(this).attr('href');
        $('.selector').removeClass('active');
        $(this).addClass('active');
        $(targets).removeClass('active');
        $(newActive).addClass('active');
        
    })
});